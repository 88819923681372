[data-theme='light'] {
	--background: #f2f2f2;
	--primary: #282828;
	--shadow: rgba(0, 0, 0, 5);
	--buttons: #012f57;
	--text: #f9c333;
	--filter: brightness(70%);
	--borders: #012f57;
	--hover-text: #f9c333;
}

[data-theme='dark'] {
	--background: #282828;
	--primary: #f2f2f2;
	--shadow: rgba(242, 242, 242, 0.1);
	--buttons: #282828;
	--text: #f2f2f2;
	--filter: brightness(185%);
	--borders: #f2f2f2;
	--hover-text: #8dd3dd;
}

* {
	-webkit-appearance: none;
	-moz-appearance: none;
	z-index: 0;
}

#menu {
	float: left;
	width: 100px;
	margin-top: 2px;
}

#theme {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
	transition: 0.2s ease-out;
}

#theme:hover {
	cursor: pointer;
}

.banner-container {
	justify-content: center;
	display: flex;
}

.banner {
	background-color: rgb(255, 128, 128);
	padding: 20px 40px 10px 40px;
	border-radius: 0px 0px 15px 15px;
	filter: drop-shadow(3px 3px 3px);
	font-size: x-large;
	animation: banner-ease ease-in-out 2s forwards;
	text-align: center;
	cursor: default;
}

@keyframes banner-ease {
	from {
		transform: translateY(-7vw);
	}
	to {
		transform: translateY(-25px);
	}
}

.rotate-effect {
	transition: transform 1.5s ease;
}

.rotate-effect:hover {
	transform: rotate(360deg);
}

#title {
	margin: 0 auto;
	width: 100px;
}

.gimmicks {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 25px;
}

#blob-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

body {
	font: 100% 'Cabin', 'Roboto', Helvetica Neue, Helvetica, Arial, sans-serif;
	margin: 0;
	padding: 0;
	background-color: var(--background);
	color: var(--primary);
}

.about {
	margin-left: 30px;
}

.code {
	background: #000;
}

.menu {
	position: fixed;
	top: 68px;
	left: -240px;
	height: 100%;
	width: 220px;
	background-color: var(--background);
	opacity: 0;
	line-height: 3em;
	padding: 1.5em;
	z-index: 1;
}

.menu.show {
	left: -20px;
	opacity: 0.97;
	animation-name: enter;
	animation-duration: 0.5s;
	z-index: 1;
}

.menu.hide {
	animation-name: exit;
	animation-duration: 0.5s;
}

.menu ul {
	list-style-type: none;
}

.menu a {
	font-size: 1.4em;
	font-weight: 700;
	text-decoration: none;
	color: var(--primary);
	transition: all 0.4s ease 0s;
}

.menu a:hover {
	color: var(--hover-text);
}

.content {
	background-color: var(--background);
	transition: all 0.4s ease 0s;
}

.content h1 {
	font-size: 3em;
	text-align: center;
	color: #4277bb;
}

.content h3 {
	text-align: center;
	font-weight: 100;
}

/* .content a {
	cursor: default;
} */

.about a,
.four-o-four a {
	text-decoration: underline;
	color: var(--primary);
	cursor: pointer;
	transition: all 0.4s ease 0s;
}

.about a:hover,
.four-o-four a:hover {
	color: var(--hover-text);
}

.text,
.personal {
	margin: 0px 100px 100px 200px;
	position: absolute;
}

.personal a {
	color: #5c9ba4;
	text-decoration: none;
}

.personal a:hover {
	color: #f9c333;
}

.header {
	position: fixed;
	width: 100%;
	height: 65px;
	z-index: 1;
	display: flex;
	flex-direction: row;
}

.menu-bar {
	width: 35px;
	height: 35px;
	margin-top: 14px;
	margin-right: 25px;
	cursor: pointer;
	transition: 0.2s ease-out;
}

.menu-bar:hover {
	transform: scale(1.15);
	transition: 0.4s ease-in;
}

div.header h1 {
	position: absolute;
	top: 50%;
	left: 50vw;
	transform: translate(-50%, -50%);
	margin: 0;
	white-space: nowrap;
}

div.container {
	width: 100%;
	margin: 0px;
}

div.header {
	color: var(--text);
	background: var(--buttons);
	text-align: center;
	border-bottom: 1.5px var(--borders) solid;
}

div.footer {
	color: var(--text);
	background: var(--buttons);
	text-align: center;
	border-top: 1.5px var(--borders) solid;
	border-bottom: 1.5px var(--borders) solid;
}

.footer {
	padding: 10px;
}

.footer a {
	color: var(--text);
}

div.content {
	padding: 5em 1em 1em 1em;
}

.contact {
	text-align: center;
	margin: 25px 0px 0px 400px;
	position: absolute;
}

.inner-flex button.button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: 'Cabin', Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 1.5em;
	font-weight: 700;
	color: var(--text);
	background: var(--buttons);
	width: 90%;
	padding: 10px;
	margin: 15px;
	cursor: pointer;
	border: 1.5px var(--borders) solid;
	border-radius: 15px;
	box-shadow: 0px 4px 12px var(--shadow);
	transition: all 0.4s ease 0s;
	pointer-events: auto;
}

.algo-name {
	flex-grow: 1;
	text-align: center;
}

.pseudocode-icon {
	display: flex;
	align-items: center;
	margin-top: -4px;
	margin-bottom: -4px;
}

.inner-flex button.button:hover,
.inner-flex button.button:active {
	filter: var(--filter);
}

.inner-flex button.button:focus,
.inner-flex a:focus {
	outline: none;
}

.inner-flex button.button::-moz-focus-inner,
.inner-flex a::-moz-focus-inner {
	border: 0;
}

.outer-flex {
	display: flex;
	align-items: center;
	flex-direction: column;
	pointer-events: none;
}

.inner-flex {
	display: flex;
	flex-wrap: wrap;
	width: 90%;
}

.divider {
	width: 100%;
	text-align: center;
	font-size: 1.5em;
	margin: 20px 5px 20px 5px;
	white-space: nowrap;
}

.divider span {
	background-color: #b0b3b8;
	color: white;
	border-radius: 15px;
	padding: 5px 10px 5px 10px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 1280px) {
	.content a {
		width: 25%;
	}
}

@media screen and (min-width: 1020px) and (max-width: 1280px) {
	.content a {
		width: 33.3%;
	}
}

@media screen and (min-width: 700px) and (max-width: 1020px) {
	.content a {
		width: 50%;
	}
}

@media screen and (max-width: 700px) {
	.content a {
		width: 100%;
	}
}

@media screen and (min-width: 1000px) {
	.header h1 {
		font-size: 32px;
		font-weight: 500;
	}
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
	.header h1 {
		font-size: 3vw;
	}
}

@media screen and (max-width: 600px) {
	.header h1 {
		display: none;
	}
}

@keyframes enter {
	from {
		left: -240px;
		opacity: 0;
	}
	to {
		left: -20px;
		opacity: 0.97;
	}
}

@keyframes exit {
	from {
		left: -20px;
		opacity: 0.97;
	}
	to {
		left: -240px;
		opacity: 0;
	}
}
